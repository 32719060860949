import React from "react"

class BodyOverride extends React.Component {

    componentDidMount() {
		document.body.style.backgroundColor = 'white'
    }

    render() {
        return (
            <style></style>
        )
    }
}

export default BodyOverride;